.input:hover
{
	box-shadow: 2px 2px 14px -2px rgba(143,143,143,1) !important;
}

.suggestions
{
	z-index: 99;
}

.btn:focus
{
	box-shadow: 0px 0px 0px 0px rgba(143,143,143,1) !important;
}

.form-control:focus
{
	box-shadow: 0px 0px 0px 0px rgba(143,143,143,1) !important;
}
